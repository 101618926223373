/* global Vue, $ */

(function ($) {
  const scrollManager = {
    timer: null,
    start(dom, value) {
      if (this.timer) this.stop();
      if (!dom) return;
      this.timer = setInterval(() => {
        const left = dom.scrollLeft;
        dom.scrollLeft = left + value; // eslint-disable-line no-param-reassign
      }, 5);
    },
    stop() {
      if (!this.timer) return;
      clearInterval(this.timer);
      this.timer = null;
    },
  };

  window.scrollSubMenu = new Vue({
    el: '#scroll-submenu',
    data: {
      isLeftBtnActive: false,
      isRightBtnActive: false,
    },
    computed: {
      leftActive() {
        return this.isLeftBtnActive ? 'active' : '';
      },
      rightActive() {
        return this.isRightBtnActive ? 'active' : '';
      },
    },
    methods: {
      init() {
        const { container } = this.$refs;
        const containerWidth = $(container).outerWidth(true);
        const contentWidth = container.scrollWidth;
        if (contentWidth > containerWidth) {
          this.isRightBtnActive = true;
        }
      },
      scroll(e) {
        const left = e.target.scrollLeft;
        const containerWidth = e.target.scrollWidth;
        const contentWidth = $(e.target.firstElementChild).outerWidth(true);
        const maxLeft = containerWidth - contentWidth;
        if (left === 0) {
          this.isLeftBtnActive = false;
        } else if (left >= maxLeft) {
          this.isRightBtnActive = false;
        } else {
          this.isLeftBtnActive = true;
          this.isRightBtnActive = true;
        }
      },
      rightScrollStart() {
        const { container } = this.$refs;
        scrollManager.start(container, 2);
      },
      rightScrollEnd() {
        scrollManager.stop();
      },
      leftScrollStart() {
        const { container } = this.$refs;
        scrollManager.start(container, -2);
      },
      leftScrollEnd() {
        scrollManager.stop();
      },
    },
  });
}($));
